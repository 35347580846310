import { Controller } from "@hotwired/stimulus";
import FileInput from "@uppy/file-input";
import ProgressBar from "@uppy/progress-bar";
import Informer from "@uppy/informer";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { uppyInstance, uploadedFileData } from "../uppy";
// import { Uppy } from "@uppy/core";

export default class extends Controller {
  static targets = ["input", "result", "thumbnailPreview", "filePreview"];
  static values = { types: Array, server: String };

  connect() {
    console.log("SINGLE UPLOAD CONTROLLER CONNECTED::::::::");
    this.inputTarget.classList.add("d-none");
    this.uppy = this.createUppy();
  }

  // disconnect() {
  //   this.uppy.close();
  // }

  createUppy() {
    const uppy = uppyInstance({
      id: this.inputTarget.id,
      types: this.typesValue,
      server: this.serverValue,
    })
      .use(FileInput, {
        target: this.inputTarget.parentNode,
        locale: { strings: { chooseFiles: "Choose file" } },
      })
      .use(Informer, {
        target: this.inputTarget.parentNode,
      })
      .use(ProgressBar, {
        target: this.hasThumbnailPreviewTarget ? this.thumbnailPreviewTarget.parentNode : this.filePreviewTarget.parentNode,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 600,
      });

    uppy.on("upload-success", (file, response) => {
      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      const uploadedData = uploadedFileData(file, response, this.serverValue);
      this.resultTarget.value = uploadedData;
      console.log("UPLOAD SUCCESS::::::::: ", uploadedData, file.name);
      if (this.hasFilePreviewTarget) {
        this.filePreviewTarget.textContent = file.name;
      }
    });

    uppy.on("thumbnail:generated", (file, preview) => {
      console.log("THUMBNAIL GENERATED::::::::: ", file, preview);
      this.thumbnailPreviewTarget.src = preview;
    });

    uppy.on("upload-error", function (file, error) {
      console.log("UPLOAD ERROR:::: ", error);
    });

    return uppy;
  }
}
