import { Controller } from "@hotwired/stimulus";
// // import videojs from "video.js";

export default class extends Controller {
  //   static targets = ["unitialisedAudio"];
  //   connect() {
  //     this.initialiseAudioPlayers();
  //     const self = this;
  //     this.element.addEventListener("pagination::itemsLoaded", function () {
  //       setTimeout(() => self.initialiseAudioPlayers(), 300);
  //     });
  //     this.element.addEventListener("contentLoader::loaded", function () {
  //       setTimeout(() => self.initialiseAudioPlayers(), 300);
  //     });
  //     this.element.addEventListener("pagination::itemsLoaded", function () {
  //       setTimeout(() => self.initialiseAudioPlayers(), 300);
  //     });
  //   }
  //   initialiseAudioPlayers() {
  //     // this.unitialisedAudioTargets.forEach((target) => {
  //     //   target.dataset.target = target.dataset.target.replace("initialise-audio.unitialisedAudio", "");
  //     //   var player = videojs(target, {
  //     //     controlBar: {
  //     //       fullscreenToggle: false,
  //     //       pictureInPictureToggle: false,
  //     //     },
  //     //   });
  //     //   var bpb = player.getChild("BigPlayButton");
  //     //   bpb.controlText("Play audio");
  //     // });
  //   }
}
