// controllers/tabs_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "pane"];

  connect() {
    console.log("TAB CONTROLLER CONNECTED", this.tabTargets);
    // this.showTab("project-updates"); // Default to 'project-updates'
  }

  switch(event) {
    event.preventDefault();
    // const tab = event.currentTarget.dataset.tab;
    this.showTab(event.currentTarget);
  }

  showTab(tab) {
    this.tabTargets.forEach((tabElement) => {
      const currentTab = tabElement.dataset.tab === tab.dataset.tab;
      console.log(`Tab:::: ${currentTab}`, tabElement.dataset.tab, tab.dataset.tab);
      tabElement.dataset.activeStatus = currentTab ? "active" : "inactive";
    });

    const inactiveTabs = this.tabTargets.filter((tab) => tab.dataset.activeStatus === "inactive");

    const activeTab = this.tabTargets.find((tab) => tab.dataset.activeStatus === "active");
    const spanChild = activeTab.querySelector("span"); // Immediate span child
    const linkChild = activeTab.querySelector("a"); // Immediate link child
    if (spanChild) {
      spanChild.classList.remove("hidden"); // Add hidden class to span
    }

    if (linkChild) {
      linkChild.classList.add("hidden"); // Remove hidden class from link
    }

    inactiveTabs.forEach((tab) => {
      const spanChild = tab.querySelector("span"); // Immediate span child
      const linkChild = tab.querySelector("a"); // Immediate link child

      if (spanChild) {
        spanChild.classList.add("hidden"); // Add hidden class to span
      }

      if (linkChild) {
        linkChild.classList.remove("hidden"); // Remove hidden class from link
      }
    });

    this.paneTargets.forEach((pane) => {
      if (pane.id === tab.dataset.tab) {
        pane.classList.add("block");
        pane.classList.remove("hidden");
      } else {
        pane.classList.add("hidden");
        pane.classList.remove("block");
      }
    });
  }
}
