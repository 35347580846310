import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  open(event) {
    const wday = event.target.dataset.sourceRuleId;
    const modal = this.modalTarget;

    const action = modal.getAttribute("action");
    const updatedAction = action.replace("source_rule_id", wday);
    modal.setAttribute("action", updatedAction);

    console.log(modal);
    const checkboxes = modal.querySelectorAll("input[type='checkbox']");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = checkbox.value === wday;
      checkbox.disabled = checkbox.value === wday;
    });

    modal.classList.remove("hidden");
  }

  close() {
    this.modalTarget.classList.add("hidden");
  }
}
