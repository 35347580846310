import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import XHRUpload from "@uppy/xhr-upload";

export function uppyInstance({ id, types, server }) {
  const uppy = new Uppy({
    id: id,
    autoProceed: true,
    restrictions: {
      allowedFileTypes: types,
    },
  });

  if (server == "s3") {
    uppy.use(AwsS3, {
      endpoint: "/", // will call Shrine's presign endpoint mounted on `/s3/params`
    });
  } else if (server == "s3_multipart") {
    uppy.use(AwsS3, {
      shouldUseMultipart: true,
      endpoint: "/", // will call uppy-s3_multipart endpoint mounted on `/s3/multipart`
    });
  } else {
    uppy.use(XHRUpload, {
      endpoint: "/upload", // Shrine's upload endpoint
    });
  }

  return uppy;
}

export function uploadedFileData(file, response, server) {
  console.log("UPLOADED FILE DATA:::::: ", file, response, server);
  if (server == "s3") {
    const id = file.meta["key"].match(/^cache\/(.+)/)[1]; // object key without prefix

    return JSON.stringify(fileData(file, id));
  } else if (server == "s3_multipart") {
    const id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1]; // object key without prefix

    return JSON.stringify(fileData(file, id));
  } else {
    return JSON.stringify(response.body);
  }
}

// constructs uploaded file data in the format that Shrine expects
function fileData(file, id) {
  return {
    id: id,
    storage: "cache",
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    },
  };
}
