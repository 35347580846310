import { Controller } from "@hotwired/stimulus";
// import {
//   Core,
//   FileInput,
//   // Informer,
//   ProgressBar,
//   StatusBar,
//   ThumbnailGenerator,
//   AwsS3,
//   AwsS3Multipart,
// } from "uppy";

// const audioMimeTypes = [
//   "audio/aac",
//   "audio/amr",
//   "audio/m4a",
//   "audio/mp4",
//   "audio/mpeg",
//   "audio/3g2",
//   "audio/wma",
//   "audio/ogg",
//   "audio/x-vorbis+ogg",
//   "audio/wav",
//   "audio/x-m4a",
//   "audio/x-wav",
//   "audio/webm",
// ];
// const imageMimeTypes = ["image/jpeg", "image/png", "image/webp", "image/gif"];
// const videoMimeTypes = ["video/mp4", "video/quicktime", "video/x-msvideo", "video/x-ms-wmv", "application/octet-stream"];
// const is_chrome = navigator.userAgent.indexOf("Chrome") > -1;

export default class extends Controller {
  //   static targets = [
  //     "uploaderGroup",
  //     "file",
  //     "data",
  //     "cancelLink",
  //     "removeLink",
  //     "previewContainer",
  //     "progressBar",
  //     "statusBar",
  //     "previewIcon",
  //     "previewImg",
  //     "uploadingNotice",
  //     "uploadedNotice",
  //     "uploaderInformer",
  //     "inBrowserUploadRecordingButton",
  //   ];
  //   connect() {
  //     if (this.hasPreviewImgTarget) {
  //       this.previewImgTarget.src = "";
  //     }
  //     if (this.hasUploaderGroupTarget) {
  //       this.setupUppy();
  //     }
  //   }
  //   setupUppy() {
  //     let uppy = this.getUppy();
  //     this.cancelLinkTarget.addEventListener("click", (event) => {
  //       event.preventDefault();
  //       window.dispatchEvent(new Event("uploader::uploadCanceled"));
  //       this.cancel(uppy);
  //     });
  //     this.removeLinkTarget.addEventListener("click", (event) => {
  //       event.preventDefault();
  //       window.dispatchEvent(new Event("uploader::uploadRemoved"));
  //       this.remove(uppy);
  //     });
  //     if (typeof window !== "undefined" && window.addEventListener) {
  //       window.addEventListener("online", () => this.updateOnlineStatus(uppy));
  //       window.addEventListener("offline", () => this.updateOnlineStatus(uppy));
  //       setTimeout(() => this.updateOnlineStatus(), 3000);
  //     }
  //     if (this.hasInBrowserUploadRecordingButtonTarget) {
  //       const self = this;
  //       window.addEventListener("audioRecorder::recordingStarted", function () {
  //         console.log("listening for in browser recording started");
  //         // self.audioRecorder.classList.remove("state--stopped")
  //         // self.enableActionButtons()
  //         self.uploaderGroupTarget.classList.add("state--add-file-disabled");
  //       });
  //       window.addEventListener("audioRecorder::audioRemoved", function () {
  //         self.uploaderGroupTarget.classList.remove("state--add-file-disabled");
  //       });
  //       this.inBrowserUploadRecordingButtonTarget.addEventListener("click", (event) => {
  //         event.preventDefault();
  //         fetch(self.inBrowserUploadRecordingButtonTarget.dataset.audioBlob)
  //           .then((response) => response.blob())
  //           .then((blob) => {
  //             console.log("blob type: ", self.inBrowserUploadRecordingButtonTarget.dataset.audioBlobType);
  //             uppy.addFile({
  //               name: `${self.inBrowserUploadRecordingButtonTarget.dataset.name}.audio.webm`,
  //               type: self.inBrowserUploadRecordingButtonTarget.dataset.audioBlobType,
  //               data: blob,
  //             });
  //           });
  //       });
  //     }
  //     uppy.on("upload", (data) => {
  //       window.dispatchEvent(new Event("uploader::uploadStarted"));
  //       // $(this.element).trigger("uploader::uploadStarted")
  //       this.previewContainerTarget.style.display = "block";
  //       this.previewIconTarget.style.display = "inline-block";
  //       this.uploadingNoticeTarget.style.display = "block";
  //       this.uploadedNoticeTarget.style.display = "none";
  //     });
  //     uppy.on("thumbnail:generated", (file, preview) => {
  //       // show preview of the image using URL from thumbnail generator
  //       this.previewImgTarget.src = preview;
  //       this.previewIconTarget.style.display = "none";
  //     });
  //     uppy.on("upload-progress", (file, progress) => {
  //       if (this.element.dataset.resumeAttempted === "true") {
  //         this.element.dataset.resumeAttempted = false;
  //         this.uploaderInformerTarget.innerHTML = "";
  //       }
  //     });
  //     uppy.on("upload-error", (file, error, response) => {
  //       this.element.dataset.uploadFailed = true;
  //       this.uploaderInformerTarget.innerHTML = '<p class="danger" role="alert">Upload of file failed</p>';
  //     });
  //     uppy.on("upload-success", (file, response) => {
  //       this.uploadingNoticeTarget.style.display = "none";
  //       this.uploadedNoticeTarget.style.display = "block";
  //       // construct uploaded file data in the format that Shrine expects
  //       const uploadedFileData = this.uploadedFileData(file, response);
  //       // set hidden field value to the uploaded file data so that it"s submitted
  //       // with the form as the attachment
  //       this.dataTarget.value = JSON.stringify(uploadedFileData);
  //       uppy.reset();
  //       window.dispatchEvent(new Event("uploader::uploadEnded"));
  //       // $(this.element).trigger("uploader::uploadEnded")
  //     });
  //   }
  //   getUppy() {
  //     switch (this.element.dataset.uploadType) {
  //       case "audio":
  //         return this.audioUploader();
  //       case "browser":
  //         return this.mediaRecordUploader();
  //       case "image":
  //         return this.imageUploader();
  //       case "video":
  //         return this.videoUploader();
  //       default:
  //         return this.assetUploader();
  //     }
  //   }
  //   cancel(uppy) {
  //     this.resetUpload(uppy);
  //   }
  //   remove(uppy) {
  //     this.resetUpload(uppy);
  //   }
  //   resetUpload(uppy) {
  //     uppy.reset();
  //     this.dataTarget.value = "";
  //     this.uploaderGroupTarget.classList.remove("state--add-file-disabled");
  //     this.element.dispatchEvent(new Event("uploader::uploadEnded"));
  //     this.previewContainerTarget.style.display = "none";
  //     this.previewImgTarget.src = "";
  //     this.previewIconTarget.display = "inline-block";
  //   }
  //   updateOnlineStatus(uppy) {
  //     const online = typeof window.navigator.onLine !== "undefined" ? window.navigator.onLine : true;
  //     if (!online) {
  //       this.uploaderInformerTarget.innerHTML = '<p class="warning" role="alert">Offline: any in progress upload will be paused</p>';
  //       this.element.dataset.wasOffline = true;
  //       if (!is_chrome) {
  //         uppy.pauseAll();
  //       }
  //     } else {
  //       if (this.element.dataset.wasOffline) {
  //         this.uploaderInformerTarget.innerHTML = '<p class="warning" role="alert">Online: attempting to restart any paused upload</p>';
  //         if (!is_chrome) {
  //           uppy.resumeAll();
  //         }
  //         this.element.dataset.wasOffline = false;
  //         this.element.dataset.resumeAttempted = true;
  //       }
  //     }
  //   }
  //   buttonText() {
  //     switch (this.element.dataset.uploadType) {
  //       case "audio":
  //         return this.element.dataset.uploadButtonText || "Add audio file";
  //       case "image":
  //         return this.element.dataset.uploadButtonText || "Add image file";
  //       case "video":
  //         return this.element.dataset.uploadButtonText || "Add video file";
  //       default:
  //         return this.element.dataset.uploadButtonText || "Add an existing file";
  //     }
  //   }
  //   uploadedFileData(file, response) {
  //     switch (this.element.dataset.uploadType) {
  //       case "audio":
  //         return this.multipartUploadedFileData(file, response);
  //       case "image":
  //         return this.singlepartUploadedFileData(file, response);
  //       case "video":
  //         return this.multipartUploadedFileData(file, response);
  //       case "browser":
  //         return this.mediaRecorderUploadedData(file, response);
  //       default:
  //         return this.multipartUploadedFileData(file, response);
  //     }
  //   }
  //   multipartUploadedFileData(file, response) {
  //     return {
  //       id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
  //       storage: "cache",
  //       metadata: {
  //         size: file.size,
  //         filename: file.name,
  //         mime_type: file.type,
  //       },
  //     };
  //   }
  //   singlepartUploadedFileData(file, response) {
  //     return {
  //       id: file.meta["key"].match(/\/cache\/(.+)/)[1], // object key without prefix
  //       storage: "cache",
  //       metadata: {
  //         size: file.size,
  //         filename: file.name,
  //         mime_type: file.type,
  //       },
  //     };
  //   }
  //   mediaRecorderUploadedData(file, response) {
  //     return {
  //       id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
  //       storage: "cache",
  //       metadata: {
  //         size: file.size,
  //         filename: file.name,
  //         mime_type: file.type,
  //       },
  //     };
  //   }
  //   assetUploader() {
  //     return (
  //       Core({
  //         autoProceed: true,
  //         restrictions: {
  //           maxNumberOfFiles: 1,
  //           allowedFileTypes: [...audioMimeTypes, ...imageMimeTypes, ...videoMimeTypes],
  //         },
  //       })
  //         .use(FileInput, {
  //           target: this.uploaderGroupTarget,
  //           pretty: true,
  //           locale: {
  //             strings: {
  //               chooseFiles: this.buttonText(),
  //             },
  //           },
  //         })
  //         // .use(Informer, {
  //         //   target: this.uploaderGroupTarget,
  //         // })
  //         .use(StatusBar, {
  //           id: "StatusBar",
  //           target: this.statusBarTarget,
  //           hideAfterFinish: false,
  //           showProgressDetails: true,
  //           hideUploadButton: true,
  //           hideRetryButton: true,
  //           hidePauseResumeButton: true,
  //           hideCancelButton: true,
  //           doneButtonHandler: null,
  //           locale: {},
  //         })
  //         .use(ProgressBar, {
  //           target: this.progressBarTarget,
  //         })
  //         .use(ThumbnailGenerator, {
  //           thumbnailWidth: 600,
  //         })
  //         .use(AwsS3Multipart, {
  //           companionUrl: "/", // will call the presign endpoint on `/s3/params`
  //           retryDelays: [0, 2000, 5000, 10000],
  //         })
  //     );
  //   }
  //   imageUploader() {
  //     return (
  //       Core({
  //         autoProceed: true,
  //         restrictions: {
  //           maxNumberOfFiles: 1,
  //           allowedFileTypes: imageMimeTypes,
  //         },
  //       })
  //         .use(FileInput, {
  //           target: this.uploaderGroupTarget,
  //           pretty: true,
  //           locale: {
  //             strings: {
  //               chooseFiles: this.buttonText(),
  //             },
  //           },
  //         })
  //         // .use(Informer, {
  //         //   target: this.uploaderGroupTarget,
  //         // })
  //         .use(ProgressBar, {
  //           target: this.progressBarTarget,
  //         })
  //         .use(ThumbnailGenerator, {
  //           thumbnailWidth: 600,
  //         })
  //         .use(AwsS3, {
  //           companionUrl: "/images/presign", // will call the presign endpoint on `/s3/params`
  //         })
  //     );
  //   }
  //   videoUploader() {
  //     return (
  //       Core({
  //         autoProceed: true,
  //         restrictions: {
  //           maxNumberOfFiles: 1,
  //           allowedFileTypes: videoMimeTypes,
  //         },
  //       })
  //         .use(FileInput, {
  //           target: this.uploaderGroupTarget,
  //           pretty: true,
  //           locale: {
  //             strings: {
  //               chooseFiles: this.buttonText(),
  //             },
  //           },
  //         })
  //         // .use(Informer, {
  //         //   target: this.uploaderGroupTarget,
  //         // })
  //         .use(ProgressBar, {
  //           target: this.progressBarTarget,
  //         })
  //         .use(StatusBar, {
  //           id: "StatusBar",
  //           target: this.statusBarTarget,
  //           hideAfterFinish: false,
  //           showProgressDetails: true,
  //           hideUploadButton: true,
  //           hideRetryButton: true,
  //           hidePauseResumeButton: true,
  //           hideCancelButton: true,
  //           doneButtonHandler: null,
  //           locale: {},
  //         })
  //         .use(ThumbnailGenerator, {
  //           thumbnailWidth: 600,
  //         })
  //         .use(AwsS3Multipart, {
  //           companionUrl: "/", // will call the presign endpoint on `/s3/params`
  //           retryDelays: [0, 2000, 5000, 10000],
  //         })
  //     );
  //   }
  //   audioUploader() {
  //     return (
  //       Core({
  //         autoProceed: true,
  //         restrictions: {
  //           maxNumberOfFiles: 1,
  //           allowedFileTypes: audioMimeTypes,
  //         },
  //       })
  //         .use(FileInput, {
  //           target: this.uploaderGroupTarget,
  //           pretty: true,
  //           locale: {
  //             strings: {
  //               chooseFiles: this.buttonText(),
  //             },
  //           },
  //         })
  //         // .use(Informer, {
  //         //   target: this.uploaderGroupTarget,
  //         // })
  //         .use(ProgressBar, {
  //           target: this.progressBarTarget,
  //         })
  //         .use(StatusBar, {
  //           id: "StatusBar",
  //           target: this.statusBarTarget,
  //           hideAfterFinish: false,
  //           showProgressDetails: true,
  //           hideUploadButton: true,
  //           hideRetryButton: true,
  //           hidePauseResumeButton: true,
  //           hideCancelButton: true,
  //           doneButtonHandler: null,
  //           locale: {},
  //         })
  //         .use(ThumbnailGenerator, {
  //           thumbnailWidth: 600,
  //         })
  //         .use(AwsS3Multipart, {
  //           companionUrl: "/", // will call the presign endpoint on `/s3/params`
  //           retryDelays: [0, 2000, 5000, 10000],
  //         })
  //     );
  //   }
  //   mediaRecordUploader() {
  //     return (
  //       Core({
  //         autoProceed: true,
  //         restrictions: {
  //           maxNumberOfFiles: 1,
  //           allowedFileTypes: audioMimeTypes,
  //         },
  //       })
  //         .use(FileInput, {
  //           target: this.uploaderGroupTarget,
  //           pretty: true,
  //           locale: {
  //             strings: {
  //               chooseFiles: this.buttonText(),
  //             },
  //           },
  //         })
  //         // .use(Informer, {
  //         //   target: this.uploaderGroupTarget,
  //         // })
  //         .use(ProgressBar, {
  //           target: this.progressBarTarget,
  //         })
  //         .use(StatusBar, {
  //           id: "StatusBar",
  //           target: this.statusBarTarget,
  //           hideAfterFinish: false,
  //           showProgressDetails: true,
  //           hideUploadButton: true,
  //           hideRetryButton: true,
  //           hidePauseResumeButton: true,
  //           hideCancelButton: true,
  //           doneButtonHandler: null,
  //           locale: {},
  //         })
  //         .use(ThumbnailGenerator, {
  //           thumbnailWidth: 600,
  //         })
  //         .use(AwsS3Multipart, {
  //           companionUrl: "/", // will call the presign endpoint on `/s3/params`
  //           retryDelays: [0, 2000, 5000, 10000],
  //         })
  //     );
  // }
}
