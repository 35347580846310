import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["confirmButton"];
  static values = {
    message: String,
  };

  connect() {
    this.confirmButtonTarget.addEventListener("click", this.confirmAction.bind(this));
  }

  confirmAction(event) {
    const message = this.messageValue || "Are you sure you want to perform this action? This cannot be undone.";
    if (!confirm(message)) {
      event.preventDefault();
    }
  }
}
