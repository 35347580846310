import { Controller } from "@hotwired/stimulus";
// import Rails from "@rails/ujs";
// var recorder, gumStream;

export default class extends Controller {
  // static targets = [
  //   "allowMicButton",
  //   "recordButton",
  //   "removeAudioButton",
  //   "inBrowserUploadRecordingButton",
  //   "preview",
  //   "uploaderInformer",
  //   "audioRecorder",
  // ];
  // connect() {
  //   if ("MediaRecorder" in window) {
  //     const self = this;
  //     this.initializeRecording();
  //     this.whenRemoveAudioButtonIsClicked();
  //     this.whenInBrowserUploadRecordingButtonIsClicked();
  //     // this.recordButtonTarget.addEventListener("click", this.toggleRecording);
  //     this.element.addEventListener("contentLoader::loaded", function () {
  //       setTimeout(() => self.initializeRecording(), 300);
  //     });
  //     window.addEventListener("uploader::uploadStarted", function () {
  //       self.disablePlayer();
  //       self.clearPreview();
  //     });
  //     window.addEventListener("uploader::uploadRemoved", function () {
  //       self.resetPlayer();
  //     });
  //     window.addEventListener("uploader::uploadCanceled", function () {
  //       self.resetPlayer();
  //     });
  //   } else {
  //     this.renderError(
  //       "Sorry, your browser doesn't support audio recordings. Please use the latest browser version and iOS 14.3 or greater on Apple devices"
  //     );
  //     this.disablePlayer();
  //   }
  // }
  // initializeRecording() {
  //   console.log("initialise recording");
  //   const self = this;
  //   this.recordButtonTarget.onclick = async () => {
  //     this.audioRecorderTarget.classList.add("state--permission-requested");
  //     this.clearError();
  //     try {
  //       const mimeType = "audio/webm";
  //       if (this.recorder && this.recorder.state == "recording") {
  //         this.stopRecording();
  //       } else {
  //         let chunks = [];
  //         const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  //         this.recorder = new MediaRecorder(stream, { type: mimeType });
  //         this.audioRecorderTarget.classList.remove("state--permission-requested");
  //         this.recorder.ondataavailable = function (e) {
  //           if (typeof e.data === "undefined") return;
  //           if (e.data.size === 0) return;
  //           chunks.push(e.data);
  //         };
  //         this.recorder.onstop = function (e) {
  //           self.recorder.stream.getAudioTracks()[0].stop();
  //           console.log("chunks", chunks);
  //           console.log("chunks count: ", chunks.length);
  //           if (chunks.length > 0) {
  //             const recording = new Blob(chunks, { type: chunks[0].type });
  //             self.renderRecording(recording, self.previewTarget);
  //           } else {
  //             self.renderError("Your recording was too short to capture.");
  //             self.resetPlayer();
  //           }
  //         };
  //         this.startRecording();
  //       }
  //     } catch (e) {
  //       this.microphonePermissionDenied();
  //     }
  //   };
  // }
  // whenRemoveAudioButtonIsClicked() {
  //   console.log("setup remove audio button");
  //   this.removeAudioButtonTargets.forEach((target) => {
  //     target.onclick = () => {
  //       window.dispatchEvent(new Event("audioRecorder::audioRemoved"));
  //       this.clearPreview();
  //       this.resetPlayer();
  //     };
  //   });
  // }
  // whenInBrowserUploadRecordingButtonIsClicked() {
  //   this.inBrowserUploadRecordingButtonTarget.onclick = () => {
  //     window.dispatchEvent(new Event("audioRecorder::uploadedRecording"));
  //     this.audioRecorderTarget.classList.remove("state--stopped");
  //     this.audioRecorderTarget.classList.add("state--uploading");
  //     this.disableActionButtons();
  //     this.recordButtonTarget.disabled = true;
  //     // this.clearPreview()
  //   };
  // }
  // // Removes audio playback from page, clears audio-blob data attribute from inBrowserRecording,
  // // and disables action buttons.
  // clearPreview() {
  //   this.previewTarget.replaceChildren();
  //   this.setInBrowserRecordingBlob();
  //   this.disableActionButtons();
  //   window.dispatchEvent(new Event("audioRecorder::playbackRemoved"));
  // }
  // // Adds disabled attribute to 'upload' and 'remove' buttons
  // disableActionButtons() {
  //   this.inBrowserUploadRecordingButtonTarget.disabled = true;
  //   this.removeAudioButtonTarget.disabled = true;
  // }
  // resetPlayer() {
  //   console.log("resetting player");
  //   this.audioRecorderTarget.classList.remove("state--stopped");
  //   this.audioRecorderTarget.classList.remove("state--uploading");
  //   this.audioRecorderTarget.classList.remove("state--disabled");
  //   this.disableActionButtons();
  //   this.recordButtonTarget.disabled = false;
  // }
  // microphonePermissionDenied() {
  //   this.audioRecorderTarget.classList.remove("state--permission-requested");
  //   this.audioRecorderTarget.classList.add("state--permission-denied");
  //   this.renderError(
  //     "Sorry your microphone has denied access. You can enable microphone access in your browser/phone settings and then head back here to send us your answer."
  //   );
  // }
  // disablePlayer() {
  //   this.audioRecorderTarget.classList.add("state--disabled");
  //   this.recordButtonTarget.disabled = true;
  // }
  // // Sets audio-blob data attribute which is used by the direct upload controller to
  // // upload the audio file.
  // setInBrowserRecordingBlob(blob_url, blob_type) {
  //   console.log("setting data: ", blob_url, blob_type);
  //   this.inBrowserUploadRecordingButtonTarget.dataset.audioBlob = blob_url;
  //   this.inBrowserUploadRecordingButtonTarget.dataset.audioBlobType = blob_type;
  // }
  // // Starts the recording, adds active state class to the microphone button,
  // // removes the playback preview and disables the action buttons
  // startRecording() {
  //   window.dispatchEvent(new Event("audioRecorder::recordingStarted"));
  //   this.audioRecorderTarget.classList.remove("state--permission-requested");
  //   this.audioRecorderTarget.classList.remove("state--stopped");
  //   this.audioRecorderTarget.classList.add("state--recording");
  //   this.recordButtonTarget.disabled = false;
  //   this.clearPreview();
  //   this.disableActionButtons();
  //   this.recorder.start();
  // }
  // // Stops the recording, removes the active state class from the microphone button,
  // // enables the action buttons
  // stopRecording() {
  //   this.audioRecorderTarget.classList.remove("state--recording");
  //   this.audioRecorderTarget.classList.add("state--stopped");
  //   this.enableActionButtons();
  //   this.recordButtonTarget.disabled = true;
  //   this.recorder.stop();
  // }
  // // Removes disabled attribute from 'upload' and 'remove' buttons
  // enableActionButtons() {
  //   this.inBrowserUploadRecordingButtonTarget.disabled = false;
  //   this.removeAudioButtonTarget.disabled = false;
  //   // this.showActionButtons()
  // }
  // // Renders the playback recording, this is triggered once the recording stops
  // renderRecording(blob, preview) {
  //   const blobUrl = URL.createObjectURL(blob);
  //   const audio = document.createElement("audio");
  //   window.dispatchEvent(new Event("audioRecorder::playbackAdded"));
  //   this.setInBrowserRecordingBlob(blobUrl, blob.type);
  //   audio.setAttribute("src", blobUrl);
  //   audio.setAttribute("controls", "controls");
  //   audio.setAttribute("id", "audioMediaPlayback");
  //   preview.appendChild(audio);
  // }
  // renderError(message) {
  //   this.uploaderInformerTarget.innerHTML = `<p class="danger" role="alert">${message}</p>`;
  // }
  // clearError() {
  //   this.uploaderInformerTarget.innerHTML = "";
  // }
}
