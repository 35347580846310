// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "./controllers";
import flatpickr from "flatpickr";

document.addEventListener("turbo:load", function () {
  tinymce.init({
    selector: "textarea.editor",
    menubar: false,
    toolbar: "bold italic | bullist numlist | link emoticons",
    plugins: "insertdatetime lists media table code emoticons link",
    branding: false,
    // toolbar:
    //   "undo redo | blocks | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | removeformat | link image emoticons",
    // plugins: "insertdatetime lists media table code help wordcount emoticons image link",
    // images_upload_url: "/tinymce_assets",
    // automatic_uploads: true,
    // file_picker_types: "image",
    // images_upload_credentials: true,
    // image_class_list: [
    //   { title: "None", value: "" },
    //   { title: "Float Left", value: "float-left" },
    //   { title: "Float Right", value: "float-right" },
    // ],
    // images_upload_url: "/tinymce_assets",
  });
});

document.addEventListener("turbo:load", () => {
  console.log("Starting flatpickr::::: ");
  flatpickr(".datepicker", {
    enableTime: false, // Set to true if you want time as well
    dateFormat: "Y-m-d", // Date format as per your requirement
  });
});
